@import 'normalize';
@import 'entypo';
@import 'breaker';
@import 'mixins';

// Gone is the desktop, table and mobile. Let's use s, m, l, xl.
$s: 500px;
$m: 800px;
$l: 1050px;
$xl: 1410px;

// Default content settings.
$contentWidth: 1030px;
$contentPadding: 15px;
$contentMaxWidth: 90%;

// Font weights.
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

// Colors
$blue: #00b5b9;
$purple: #7c3e9a;
$green: #30b900;
$greenLight: #CCFFB9;
$greenDark: #2ba600;
$orange: #ff773d;
$red: #e00438;
$lightYellow: #fff3cc;
$yellow: #fdc400;
$lighterGray: #d6d2d0;
$lightGray: #d6d2d0;
$gray: #9a9492;
$black: #24211f;
$white: #fff;

p{
	margin: 5px 0;
}

textarea{
	border: 1px solid #EEEEEE;
	width: 500px;
	max-width: 100%;
	margin: 0px 0;
	padding: 20px;
}

.item{
	font-family: arial;
	color: #000;
	text-decoration: none;
	display: block;
	padding: 15px 20px;
	background: #fff;
	border-bottom: 1px solid #eee;
}

.item:visited{
	background: #f9f9f9;
}
.item h2{
	margin-top: 0;
	margin-bottom: 0;
}

.buttons{
	margin-top: 20px;
	margin-bottom: 10px;
}

.item:visited{
	background: #f9f9f9;
}
.item h2{
	margin-top: 0;
	margin-bottom: 10px;

}
.item h4{
	margin: 0 0 10px 0;
	font-size: 16px;
}
ul{
	list-style: none;
	font-size: 14px;
	padding: 20px 0;
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
	margin: 0;
}

a{
	display: inline-block;
	padding: 10px 20px;
	background: red;
	color: #fff;
	text-decoration: none;

	&.is-submitting{
		pointer-events: none;
		background: #999;
	}
}

.filter-buttons{
	padding: 20px;

	.filter-header{
		font-weight: bold;
		margin-bottom: 10px;
	}

	button{
		background: #4AA2DC;
		color: #fff;
		border: 0;
		outline: 0;
		padding: 6px 20px;
		border-radius: 20px;
	}
}